<template>
    <div>
        <b-form>
            <b-row class="mb-1">
                <b-col>
                    <h2>
                        Busca el productor a gestionar
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="2" xl="2" class="mb-1">
                    <b-form-group label="Email" label-for="basicInput">
                        <b-form-input v-model="email" placeholder="Digite Email"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2" xl="2" class="mb-1">
                    <b-form-group label="Nombres" label-for="basicInput">
                        <b-form-input v-model="firstName" placeholder="Digite Nombres"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2" xl="2" class="mb-1">
                    <b-form-group label="Apellidos" label-for="basicInput">
                        <b-form-input v-model="lastName" placeholder="Digite Apellidos"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2" xl="2" class="mt-2">
                    <b-button variant="primary" @click="getAllCarbonProducers">
                        <feather-icon icon="SearchIcon" class="mr-50" />Filtrar
                    </b-button>
                </b-col>
                <b-col md="2" xl="2" class="mt-2 text-right" offset-lg="2">
                    <b-button v-if="userType != 'AuditorCarbono'" variant="warning" @click="addNewCarbonProducer" block>
                        <feather-icon icon="PlusIcon" class="mr-50" />Nuevo productor
                    </b-button>
                </b-col>
            </b-row>
        </b-form>

        <b-card-code :title="'Lista de productores de carbono (Cantidad: ' + carbonProducers.length + ')'" no-body>

            <b-table
                ref="selectableTable"
                :fields="fields"
                :items="carbonProducers"
                responsive
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected">
                    <template #cell(email)="data">
                        {{ (data.item.email) }}
                    </template>

                    <template #cell(firstName)="data">
                        {{ (data.item.firstName) }}
                    </template>

                    <template #cell(lastName)="data">
                        {{ (data.item.lastName) }}
                    </template>

                    <template #cell(termAcceptedDate)="data">
                        <div>{{ transformDate(data.item.termAcceptedDate) }}</div>
                    </template>
            </b-table>

        </b-card-code>
        <b-row v-if="this.carbonProducers.length <= 0" class="justify-content-center mt-3" style="text-align:center">
            <b-col style="justify-content:center" xl="4" md="6" lg="4">
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>No Hay Información para mostrar</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-modal centered ref="buttons-carbon-producer-modal" hide-footer title="¿Que deseas hacer?">
            <b-button class="mt-2" variant="primary" block @click="editCarbonProducer">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Editar Productor</span>
            </b-button>
            <b-button class="mt-2" variant="outline-primary" block @click="changePassword">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Definir Contraseña</span>
            </b-button>
        </b-modal>

        <b-modal centered ref="setNewPassword" hide-footer title="Digita la nueva contraseña">
            <b-col>
                <b-form-group label="Contraseña:" label-for="basicInput">
                    <b-form-input v-model="newPassword" placeholder="Digite nueva Contraseña"></b-form-input>
                </b-form-group>

                <b-button class="mt-2" variant="primary" block @click="setNewPassword">
                    <feather-icon icon="lockIcon" class="mr-50" />
                    <span class="align-middle">Guardar Contraseña</span>
                </b-button>
            </b-col>
        </b-modal>

        <b-modal size="lg" ref="carbonProducerModal" centered hide-footer title="Digite la siguiente información">
            <b-card-text>
                <b-form>

                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <label>Nombres</label>
                            <b-form-input v-model="userFirstName" class="mb-1" placeholder="Digite" />
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <label>Apellidos</label>
                            <b-form-input v-model="userLastName" class="mb-1" placeholder="Digite" />
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <label>Cédula o NIT</label>
                            <b-form-input v-model="documentNumber" placeholder="Digite" />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Departamento" label-for="basicInput">
                                <b-form-select @change="onDepartamentoSelect" v-model="state" :options="departamentosList"
                                    value-field="departamento" text-field="departamento" placeholder="Seleccione" />
                            </b-form-group>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Municipio/Ciudad" label-for="basicInput">
                                <b-form-select v-model="city" :options="municipiosList" placeholder="Seleccione" />
                            </b-form-group>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Celular" label-for="basicInput">
                                <b-form-input v-model="phoneNumber" placeholder="Digite" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Dirección" label-for="basicInput">
                                <b-form-input v-model="address" placeholder="Digite" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" xl="12" class="mb-1 mt-0">
                            <b-form-checkbox v-model="isCompany" checked="false" name="check-button" switch inline>
                                Es una compañia
                            </b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="RUT" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="rutFile" />
                                <b-card-text @click="openDocument(rutUrl)" v-if="rutUrl"
                                    class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon
                                        icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Copia Documento Identidad" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="documentFile" />
                                <b-card-text @click="openDocument(documentUrl)" v-if="documentUrl"
                                    class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon
                                        icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Cámara de Comercio" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="chamberOfCommerceFile" />
                                <b-card-text @click="openDocument(chamberOfCommerceUrl)" v-if="chamberOfCommerceUrl"
                                    class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon
                                        icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Certificación Truora" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="truoraPdfFile" />
                                <b-card-text @click="openDocument(truoraPdfUrl)" v-if="truoraPdfUrl"
                                    class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon
                                        icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <label>Usuario</label>
                            <b-form-input v-model="userEmail" class="mb-1" placeholder="Digite" />
                        </b-col>

                        <b-col md="6" xl="6" class="mb-0">
                            <label>Contraseña</label>
                            <b-form-input v-model="password" class="mb-1" placeholder="Digite" />
                        </b-col>
                    </b-row>


                    <b-row class="justify-content-end">
                        <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" @click="saveCarbonProducer" block>
                                Guardar
                            </b-button>
                        </b-col>
                        <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" disabled block>
                                <b-spinner small label="Cargando..." />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>

import {
    BLink, BButton, BImg, BSpinner, BRow, BCol, BFormInput, BFormTextarea, BFormGroup,
    BTable, BAlert, BBadge, BFormFile, BForm, BFormSelect, BFormCheckbox, BCardText
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils';
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import Cleave from 'vue-cleave-component';
import departamentos from '../../assets/departamentos-municipios.json'
import axios from '@axios'

export default {
    components: {
        BAlert,
        BLink,
        BButton,
        BCardText,
        BImg,
        BSpinner,
        BBadge,
        BRow,
        BCol,
        BForm,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BFormFile,
        BTable,
        BCardCode,
        BFormSelect,
        BFormCheckbox,
        Cleave,
    },
    data() {
        return {
            selectMode: 'single',
            fields: [
                { key: 'line', label: '#' },
                { key: 'email', label: 'Email' },
                { key: 'firstName', label: 'Nombres' },
                { key: 'lastName', label: 'Apellidos' },
                { key: 'farms', label: 'Finca(s)' },
                { key: 'termAcceptedDate', label: 'Aceptó Términos' }
            ],
            carbonProducers: [{}],
            email: null,
            firstName: null,
            lastName: null,
            termAcceptedDate: null,
            selectedCarbonProducer: {},
            isCompany: null,
            documentNumber: null,
            address: null,
            phoneNumber: null,
            state: null,
            city: null,
            rutFile: null,
            documentFile: null,
            chamberOfCommerceFile: null,
            truoraPdfFile: null,
            departamentosList: departamentos,
            departamentoSelected: '',
            municipiosList: [],
            processing: null,
            rutUrl: null,
            documentUrl: null,
            chamberOfCommerceUrl: null,
            truoraPdfUrl: null,
            userEmail: null,
            password: null,
            carbonProducerID: null,
            newPassword: null,
            userFirstName: null,
            userLastName: null,
            userType: '',
        }
    },
    created() {
        var userData = getUserData();
        this.userType = userData.userType;
        this.getAllCarbonProducers();
    },
    watch: {
        rutFile: function (val) { this.rutFile = val; this.uploadFile(val, 'rutFile'); },
        documentFile: function (val) { this.documentFile = val; this.uploadFile(val, 'documentFile'); },
        chamberOfCommerceFile: function (val) { this.chamberOfCommerceFile = val; this.uploadFile(val, 'chamberOfCommerceFile'); },
        truoraPdfFile: function (val) { this.truoraPdfFile = val; this.uploadFile(val, 'truoraPdfFile'); },

    },

    methods: {
        getAllCarbonProducers() {
            axios.get('/CarbonProducer/GetAllCarbonProducers', {
                params: {
                    email: this.email, firstName: this.firstName,
                    lastName: this.lastName, termAcceptedDate: this.termAcceptedDate,
                }
            })
                .then(response => {
                    this.carbonProducers = response.data;
                    this.carbonProducers.forEach(item => {
                        item.line = this.carbonProducers.indexOf(item) + 1
                    });
                })
        },

        onRowSelected(items) {
            if (this.userType != 'AuditorCarbono') {
                this.userSelected = items[0];
                this.selectedCarbonProducer = items[0];
                if (this.selectedCarbonProducer) this.$refs['buttons-carbon-producer-modal'].show()
            }
        },

        changePassword() {
            this.$refs['buttons-carbon-producer-modal'].hide();
            this.$refs['setNewPassword'].show()
        },

        setNewPassword() {
            this.mail = this.userSelected.email
            axios.get('/User/setNewPassword', { params: { email: this.mail, newPassword: this.newPassword } })
                .then(response => {
                    if (response) {
                        this.$refs['setNewPassword'].hide()
                        this.$bvToast.toast("Contraseña asignada con exito", { title: 'Ok', variant: 'success', solid: true })
                    } else {
                        this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true })
                    }
                })
        },

        addNewCarbonProducer() {
            this.processing = false;
                this.carbonProducerID = undefined;
                this.isCompany = null,
                this.userFirstName = null,
                this.userLastName = null,
                this.documentNumber = null,
                this.address = null,
                this.phoneNumber = null,
                this.state = null,
                this.city = null,
                this.rutUrl = null,
                this.documentUrl = null,
                this.chamberOfCommerceUrl = null,
                this.truoraPdfUrl = null,
                this.userEmail = null,
                this.password = null,
                this.$refs['carbonProducerModal'].show()

        },

        onDepartamentoSelect(event) {
            this.state = event;
            var departamento = this.departamentosList.find(x => x.departamento === event);
            this.municipiosList = departamento.ciudades;
        },

        saveCarbonProducer() {
            this.processing = true;
            var payload = {
                carbonProducerID: this.carbonProducerID,
                isCompany: this.isCompany,
                firstName: this.userFirstName,
                lastName: this.userLastName,
                documentNumber: this.documentNumber,
                address: this.address,
                phoneNumber: this.phoneNumber,
                state: this.state,
                city: this.city,
                rutUrl: this.rutUrl,
                documentUrl: this.documentUrl,
                chamberOfCommerceUrl: this.chamberOfCommerceUrl,
                truoraPdfUrl: this.truoraPdfUrl,
                email: this.userEmail,
                password: this.password,

            }
            axios.post('/CarbonProducer/SaveCarbonProducer', payload)
                .then(response => {
                    this.processing = false;
                    this.$refs['buttons-carbon-producer-modal'].hide()
                    this.$refs['carbonProducerModal'].hide();
                    this.$bvToast.toast("La información ha sido guardada", { title: 'Ok', variant: 'success', solid: true })

                    this.getAllCarbonProducers();

                })
                .catch(error => {
                    this.processing = false;
                    if (error.response) {
                        this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                    } else {
                        this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                    }
                })

        },

        editCarbonProducer() {
            this.carbonProducerID = this.selectedCarbonProducer.carbonProducerID;
            this.isCompany = this.selectedCarbonProducer.isCompany;
            this.userFirstName = this.selectedCarbonProducer.firstName;
            this.userLastName = this.selectedCarbonProducer.lastName;
            this.documentNumber = this.selectedCarbonProducer.documentNumber;
            this.address = this.selectedCarbonProducer.address;
            this.phoneNumber = this.selectedCarbonProducer.phoneNumber;
            this.state = this.selectedCarbonProducer.state;
            var departamento = this.departamentosList.find(x => x.departamento === this.state);
            this.municipiosList = departamento.ciudades;
            this.city = this.selectedCarbonProducer.city;
            this.rutUrl = this.selectedCarbonProducer.rutUrl;
            this.documentUrl = this.selectedCarbonProducer.documentUrl;
            this.chamberOfCommerceUrl = this.selectedCarbonProducer.chamberOfCommerceUrl;
            this.truoraPdfUrl = this.selectedCarbonProducer.truoraPdfUrl;
            this.userEmail = this.selectedCarbonProducer.email;

            this.getAllCarbonProducers();
            this.$refs['carbonProducerModal'].show()

        },

        uploadFile(fileToUpload, fileType) {
            this.processing = true;
            let formData = new FormData();
            formData.append('file', fileToUpload);
            axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    this.processing = false;
                    if (response.data) {
                        this.setFileUrl(fileType, response.data);
                        this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
                        this.saveProfileDetails();
                    } else {
                        this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })
                    }
                })
                .catch(error => {
                    this.processing = false;
                    if (error.response) {
                        this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                    } else {
                        this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                    }
                })
        },
        setFileUrl(fileType, fileUrl) {
            switch (fileType) {
                case 'documentFile':
                    this.documentUrl = fileUrl;
                    break;
                case 'chamberOfCommerceFile':
                    this.chamberOfCommerceUrl = fileUrl;
                    break;
                case 'truoraPdfFile':
                    this.truoraPdfUrl = fileUrl;
                    break;
                case 'rutFile':
                    this.rutUrl = fileUrl;
                    break;
            }
        },
        openDocument(fileUrl) {
            window.open(fileUrl, '_blank');
        },
        transformDate(date){
          if (date == null) {
            return "--/--/----"}
          else 
          {return date.substring(0,10)}
        }
    }
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>